import React from 'react'
import { createRoot } from 'react-dom/client'
// import '../stylesheets/application-vite.scss'
// @ts-expect-error
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle.js'
import 'trix'
import '@rails/actiontext'
import '../banners'
import '../charts'
import { CalculatorApp } from '../calculator/javascript/calculator'

const initialize = () => {
  ;(window as any).Tooltip = Tooltip

  const container = document.querySelector('[data-calculator-root]')
  if (!container) return

  const root = createRoot(container) // createRoot(container!) if you use TypeScript
  root.render(<CalculatorApp />)
}

initialize()
