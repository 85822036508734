import axios from 'axios'
import ahoy from 'ahoy.js'
import React from 'react'
import { AxisOptions, Chart } from 'react-charts'
import { useQuery } from 'react-query'
import {
  IncludeFlags,
  IncomeTimePeriod,
  LeapfundDataBK,
} from '../../common/Leapfund'
import { formatCurrency } from '../util/currency'
import { isNotNull } from '../util/filters'
import {
  formatCurrencyPrecise,
  formatCurrencySmart,
} from '../../common/util/formatCurrencyPrecise'
import { Spinner } from './Spinner'

type RelativeDifference = 'loss' | 'gain' | 'equal'

type StaggeredRequest = {
  income: number
  sizePercent: number
  key: number
  net_income?: RelativeIncomeValue
  welfare_amount?: RelativeIncomeValue
  ssdi_amount?: RelativeIncomeValue
  ssi_spouse_amount?: RelativeIncomeValue
  ssi_you_amount?: RelativeIncomeValue
  ssi_kids_amount?: RelativeIncomeValue
  ssi_other_amount?: RelativeIncomeValue
  foodstamp_amount?: RelativeIncomeValue
  wic_amount?: RelativeIncomeValue
  other_income?: RelativeIncomeValue
  unemployment_amount?: RelativeIncomeValue
  work_study_amount?: RelativeIncomeValue
  child_support_income?: RelativeIncomeValue
}

const comparedFields = [
  // Main Income ( stay as is)
  'net_income',

  // SSI
  // SSI you amount - show $
  // SSI other (show icon of tooltip)
  // SSI kids (show icon of tooltip)
  // SSI spouse (show icon of tooltip)
  'ssi_you_amount',
  'ssi_other_amount',
  'ssi_kids_amount',
  'ssi_spouse_amount',

  // SSDI (show icon of tooltip)
  'ssdi_amount',

  // Unemployment (show icon of tooltip)
  'unemployment_amount',

  // Cash Assistance
  'welfare_amount',

  // SNAP
  'foodstamp_amount',

  // WIC
  'wic_amount',

  // Childcare
  'child_support_income',
] as const

const comparedFieldLabels: Record<(typeof comparedFields)[number], string> = {
  // Main Income ( stay as is)
  net_income: 'Estimated Net Income',
  // SSI,
  // SSI you amount - show $,
  // SSI other (show icon of tooltip),
  // SSI kids (show icon of tooltip),
  // SSI spouse (show icon of tooltip),
  ssi_you_amount: 'Client SSI',
  ssi_other_amount: 'Other SSI',
  ssi_kids_amount: 'Kids SSI',
  ssi_spouse_amount: 'Spouse SSI',
  // SSDI (show icon of tooltip),
  ssdi_amount: 'SSDI',
  // Unemployment (show icon of tooltip),
  unemployment_amount: 'Unemployment',
  // Cash Assistance,
  welfare_amount: 'Cash Assistance',
  // SNAP,
  foodstamp_amount: 'SNAP',
  // WIC,
  wic_amount: 'WIC',
  // Childcare,
  child_support_income: 'Childcare',
}

type RelativeIncomeValue = {
  value: number
  differenceToPrevious?: number
  relativeDifference?: RelativeDifference
}

enum DeltaColor {
  green = '#28965A',
  red = '#AF1B3F',
  gray = '#AAAAAA',
}

const url = new URL(window.location.href)
const vizRequests = url.searchParams.get('viz_requests')
const vizRequestsNumber = vizRequests != null ? parseInt(vizRequests, 0) : null

let numberOfRequests =
  typeof vizRequestsNumber === 'number' && Number.isInteger(vizRequestsNumber)
    ? vizRequestsNumber
    : 16

export const IncomeVisualization = ({
  currentData,
  originalData,
  includeFlags,
  min,
  max,
  step,
  onClickIncome,
}: {
  currentData: LeapfundDataBK
  originalData: LeapfundDataBK
  min: number
  max: number
  step: number
  includeFlags: IncludeFlags
  onClickIncome: (amount: number) => void
}) => {
  let staggeredRequests: Array<StaggeredRequest> = []

  const division = (max - min) / numberOfRequests

  for (let i = 0; i < numberOfRequests; i++) {
    const position = division * i - ((division * i) % step)
    const n = i + 1
    const nextPosition =
      i === numberOfRequests - 1 ? max : division * n - ((division * n) % step)
    const sizePercent = (nextPosition - position) / (max - min)

    staggeredRequests.push({
      income: position,
      sizePercent,
      key: i,
    })
  }

  const query = useQuery(
    [
      `incomeviz`,
      {
        includeFlags,
        params: originalData.params,
        hours: currentData.params.hours_per_week,
        people: currentData.params.persons,
      },
    ],
    async () => {
      const promises = staggeredRequests.map((sr) => {
        // this is a bit weird– but consistent with old behavior + the api
        const searchParams = new URLSearchParams(window.location.search)
        const urlValues = Object.fromEntries(searchParams.entries())

        const householdUrlData: Record<string, number> = {}
        currentData.params.persons.forEach((age, i) => {
          householdUrlData[`persons[${i}][age]`] = age
        })

        let params: any = {
          ...urlValues,
          ...householdUrlData,
          income: sr.income,
          hours_per_week: currentData.params.hours_per_week,
        }

        const payload = {
          current: params,
          original: originalData.params,
          include_flags: includeFlags,
          disable_bing: true,
        }

        const result = axios.post<LeapfundDataBK>('api/calculate', payload)
        return result
      })

      return await Promise.all(promises)
    },
  )

  if (query.status === 'success') {
    let prevList: typeof staggeredRequests = []

    for (const req of staggeredRequests) {
      const i = staggeredRequests.indexOf(req)
      const prev = prevList[prevList.length - 1]

      for (let field of comparedFields) {
        // const yy = query.data[i].data.calculated.
        let value = query.data[i].data.incomes[field]
        const prevField = prev ? prev[field] : null
        let differenceToPrevious = prevField ? value - prevField.value : 0

        const allPreviousValues = prevList
          .map((r) => r[field]?.value)
          .filter(isNotNull)

        const max = Math.max(...allPreviousValues)

        let relativeDifference: RelativeDifference =
          value === max ? 'equal' : value < max ? 'loss' : 'gain'

        req[field] = {
          value: value,
          differenceToPrevious,
          relativeDifference,
        }
      }

      prevList.push(req)
    }
  }

  return (
    <div>
      <div className="d-flex flex-column" style={{ height: 80 }}>
        {query.data ? (
          <NetChart
            staggeredRequests={staggeredRequests}
            min={min}
            max={max}
            onClickIncome={onClickIncome}
            incomeTimePeriod={originalData.params.income_time_period}
            isChildcareEnabled={includeFlags.include_child_care_voucher}
          />
        ) : query.status === 'error' ? (
          <div className="d-flex flex-grow-1 justify-content-center py-2 align-items-center">
            <div>
              Error:{' '}
              {query.error instanceof Error ? query.error.message : 'unknown'}
            </div>
            <button
              type="button"
              onClick={() => window.location.reload()}
              className="btn btn-secondary btn-sm ml-2"
            >
              Reload
            </button>
          </div>
        ) : query.status === 'loading' ? (
          <div className="d-flex flex-grow-1 justify-content-center py-2 align-items-center">
            <Spinner />
            <div className="ml-2">Generating chart…</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

const NetChart = ({
  staggeredRequests,
  min,
  max,
  onClickIncome,
  incomeTimePeriod,
  isChildcareEnabled,
}: {
  staggeredRequests: Array<StaggeredRequest>
  min: number
  max: number
  onClickIncome: (amount: number) => void
  incomeTimePeriod: IncomeTimePeriod
  isChildcareEnabled: boolean
}) => {
  const data = [
    {
      label: ``,
      data: staggeredRequests,
    },
  ]

  let relevantFields = comparedFields.filter((comparedField) => {
    // sanity check
    if (staggeredRequests[0] == null) return false

    const isAllZero = staggeredRequests.every(
      (sr) => (sr[comparedField]?.value ?? 0) === 0,
    )

    if (isAllZero) return false

    const firstValue = staggeredRequests[0][comparedField]?.value ?? 0
    const isAllSame = staggeredRequests.every(
      (sr) => (sr[comparedField]?.value ?? 0) === firstValue,
    )

    if (isAllSame) return false

    return true
  })

  const xAxis = React.useMemo(
    (): AxisOptions<StaggeredRequest> => ({
      getValue: (datum) => datum.income,
      scaleType: 'linear',
      formatters: {
        tooltip: (value) => `${formatCurrencyPrecise(value)}`,
      },
      min,
      max,
    }),
    [max, min],
  )

  const yAxis = React.useMemo(
    (): AxisOptions<StaggeredRequest>[] => [
      {
        getValue: (datum) => datum.net_income?.value ?? 0,
        elementType: 'bar',
        show: false,
        scaleType: 'linear',
        min: 0,
        hardMin: 0,
        formatters: {
          tooltip: (value) => `Net Income: ${formatCurrencyPrecise(value)}`,
        },
      },
    ],
    [],
  )

  if (!data) return null

  return (
    <Chart
      options={{
        data,
        primaryAxis: xAxis,
        secondaryAxes: yAxis,
        onClickDatum: (datum) => {
          if (!datum) return
          onClickIncome(datum?.originalDatum.income)
        },
        onFocusDatum: () => {
          ahoy.track('Results Page - Cliff Visualization', {
            language: 'JavaScript',
          })
        },
        getDatumStyle: (datum) => {
          const colorMap: Record<RelativeDifference, DeltaColor> = {
            // equal: '#444',
            equal: DeltaColor.green,
            gain: DeltaColor.green,
            loss: DeltaColor.red,
          }

          if (!datum.originalDatum.net_income?.relativeDifference) return {}

          if (datum.index === 0) {
            return { color: DeltaColor.gray }
          }

          return {
            color: colorMap[datum.originalDatum.net_income.relativeDifference],
          }
        },
        tooltip: {
          render: (props) => {
            let datum = props.focusedDatum
            if (datum == null) return null

            let rows: Array<
              [keyof typeof comparedFieldLabels, RelativeIncomeValue]
            > = []

            for (let field of relevantFields) {
              let riv = datum.originalDatum[field]
              if (riv) {
                rows.push([field, riv])
              }
            }

            if (rows.length === 0) return null

            const color = (diff: number | undefined) => {
              if (diff == null) return undefined
              if (diff >= 1) return DeltaColor.green
              if (diff <= -1) return DeltaColor.red
              return undefined
            }

            return (
              <div className="p-3 rounded shadow bg-white">
                <table>
                  <thead>
                    <tr>
                      <th className="bold">
                        {timePeriodLabels[incomeTimePeriod]}:{' '}
                        {formatCurrencySmart(datum.primaryValue)}
                      </th>
                      <th className="pr-2">Value</th>
                      <th colSpan={2}>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map(([field, riv], i) => (
                      <tr key={i}>
                        <td className="bold pr-2">
                          {comparedFieldLabels[field]}
                        </td>
                        <td className="pr-2">{formatCurrency(riv.value)}</td>
                        <td style={{ color: color(riv.differenceToPrevious) }}>
                          {(riv.differenceToPrevious ?? 0) > 0 ? (
                            <div aria-hidden={true}>↑</div>
                          ) : (riv.differenceToPrevious ?? 0) < 0 ? (
                            <div aria-hidden={true}>↓</div>
                          ) : null}
                        </td>
                        <td style={{ color: color(riv.differenceToPrevious) }}>
                          {formatCurrency(riv.differenceToPrevious ?? 0)}
                        </td>
                      </tr>
                    ))}
                    {isChildcareEnabled ? (
                      <tr>
                        <td>Childcare</td>
                        <td colSpan={2}>
                          <div className="text-muted">Coming soon</div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            )
          },
        },
        primaryCursor: {
          show: false,
          showLine: false,
          showLabel: false,
        },
        secondaryCursor: {
          show: false,
          showLine: false,
          showLabel: false,
        },
      }}
    />
  )
}

const timePeriodLabels: Record<IncomeTimePeriod, string> = {
  hour: 'Hourly',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Annual',
}
