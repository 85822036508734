import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  LeapfundDataBK,
  LeapfundState,
  UserAndOrgData,
} from '../common/Leapfund'
import '../stylesheets/calculator.scss'
import { ErrorFallback } from './components/Errors'
import FinancialInformation from './components/FinancialInformation'

declare global {
  interface Window {
    bootstrapData: unknown
    lfCurrentData: unknown
    lfState: unknown
  }
}

const originalData: LeapfundDataBK = window.bootstrapData as any
const userAndOrgData: UserAndOrgData = window.lfCurrentData as any

// const node = document.getElementById('react-root')
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export const CalculatorApp = () => (
  <React.Fragment>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <FinancialInformation
          originalData={originalData}
          userAndOrgData={userAndOrgData}
          state={window.lfState as any}
        />
      </QueryClientProvider>
    </ErrorBoundary>
  </React.Fragment>
)
