import $ from 'jquery'
// @ts-expect-error
import Cookies from 'js-cookie'

$(document).ready(() => {
  if ($('.banner-alert').length) {
    $('.close-banner').on('click', (e: any) => {
      const id = $(e.target).closest('.banner-alert').data('banner-id')
      console.log(id)
      Cookies.set(`banner_hide_${id}`, true)
    })
  }
})
