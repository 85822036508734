import ahoy from 'ahoy.js'
import axios from 'axios'
import exclamationIcon from 'bootstrap-icons/icons/exclamation-circle.svg'
import { isEqual } from 'lodash'
import React, { ReactElement, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import exclamationIconGreen from '../../assets/exclamation-circle-green.svg'
import {
  FlagChangeSource,
  IncludeFlags,
  IncomeTimePeriod,
  LeapfundDataBK,
  LeapfundState,
  UserAndOrgDataLegacy,
} from '../../common/Leapfund'
import { currentAmount } from '../../common/util/currentAmount'
import constants from '../../data/constants.json'
import { BKApi } from '../types/BenefitKitchen'
import { formatCurrency } from '../util/currency'
import { Environment, environment } from '../util/environment'
import { Benefits } from './Benefits'
import { Details } from './Details'
import { Disclaimer } from './Disclaimer'
import { ErrorFallback } from './Errors'
import { formatCurrencyPrecise } from '../../common/util/formatCurrencyPrecise'
import { IncomeVisualization } from './IncomeVisualization'
import { Spinner } from './Spinner'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'

export default function FinancialInformation({
  originalData,
  userAndOrgData,
  state,
}: {
  originalData: LeapfundDataBK
  userAndOrgData: UserAndOrgDataLegacy
  state: LeapfundState
}) {
  const [mode, setMode] = useState<'calculated' | 'direct'>('calculated')
  const [flagChangeSource, setFlagChangeSource] = useState<
    FlagChangeSource | undefined
  >()

  const [incomeInput, setIncomeInput] = useState(originalData.params.income)
  const [incomeHoursInput, setIncomeHoursInput] = useState(
    originalData.params.hours_per_week,
  )
  const [incomeInputDebounced] = useDebounce(incomeInput, 500)
  const [incomeHoursInputDebounced] = useDebounce(incomeHoursInput, 500)

  const initialPeopleValue = (persons: Array<number>): Array<string> => {
    const missing: Array<string> = new Array(
      Math.max(6 - persons.length, 0),
    ).fill(``)

    const value = [...persons.map((num) => num.toString()), ...missing]

    return value
  }

  const isChild = (age: number) => age < 18

  const [adults, setAdults] = useState(
    initialPeopleValue(
      originalData.params.persons.filter((age) => !isChild(age)),
    ),
  )

  const [children, setChildren] = useState(
    initialPeopleValue(originalData.params.persons.filter(isChild)),
  )

  const isDebug =
    new URL(document.location.href).searchParams.get('view') === 'debug'

  const [includeFlags, setIncludeFlags] = useState<IncludeFlags>({
    include_child_care_voucher: false,
    include_snap: false,
    include_welfare: false,
    include_wic: false,
  })

  const source =
    mode === 'calculated'
      ? 'calculated'
      : mode === 'direct'
        ? 'incomes'
        : undefined

  if (!source) throw new Error(`invariant: bad mode ${mode}`)

  const originalBkData: BKApi.Benefits = originalData.bkResult.response.data

  const originalCalculators = originalBkData.calculators
  const [isEnabled, setIsEnabled] = useState(false)

  const query = useQuery(
    [
      `calculation`,
      {
        incomeInput: incomeInputDebounced,
        incomeHoursInput: incomeHoursInputDebounced,
        adults,
        children,
        includeFlags,
      },
    ] as const,
    async (options): Promise<LeapfundDataBK> => {
      const { queryKey } = options
      const [
        ,
        { adults, children, incomeInput, incomeHoursInput, includeFlags },
      ] = queryKey

      setMode('direct')
      const householdUrlData: Record<string, number> = {}

      const people = [...adults, ...children]
        .filter((ageStr) => ageStr.trim() !== ``)
        .map((ageStr) => parseInt(ageStr, 10))
        .filter((age) => !Number.isNaN(age))

      people.forEach((age, i) => {
        householdUrlData[`persons[${i}][age]`] = age
      })

      // this is a bit weird– but consistent with old behavior + the api
      const searchParams = new URLSearchParams(window.location.search)
      const urlValues = Object.fromEntries(searchParams.entries())

      for (let key of Object.keys(urlValues)) {
        if (key.startsWith('persons[')) {
          delete urlValues[key]
        }
      }

      let params: any = {
        ...urlValues,
        ...householdUrlData,
        income: incomeInput,
        hours_per_week: incomeHoursInput,
        // TODO?
        // people: people.map((num) => ({ age: num })),
      }

      const result = await axios.post<LeapfundDataBK>('api/calculate', {
        current: params,

        original: originalData.params,
        include_flags: includeFlags,
      })

      return result.data
    },
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  )

  const currentData = query.data ?? originalData
  const currentCalculators = currentData.bkResult.response.data.calculators

  const marketRateRentDelta = originalData.bingData.response
    ? currentData.expenses.housing_costs -
      originalData.bingData.response.expenses.rent
    : undefined

  const snap = new PredictedField({
    value: currentData[source].foodstamp_amount,
    originalValue: originalData.incomes.foodstamp_amount,
    prediction:
      originalCalculators.foodstamps.foodstamp_eligibility === 'Eligible'
        ? originalCalculators.foodstamps.foodstamp_amount
        : null,
  })

  const wic = new PredictedField({
    value: currentData[source].wic_amount,
    originalValue: originalData.incomes.wic_amount,
    prediction:
      originalCalculators.wic.wic_eligibility === 'Eligible' &&
      originalCalculators.wic.calc_status !== 'client_reported'
        ? originalCalculators.wic.wic_amount ?? null
        : null,
    calcStatus: currentCalculators.wic.calc_status,
  })

  const cashAssistance = new PredictedField({
    value: currentData[source].welfare_amount,
    originalValue: originalData.incomes.welfare_amount,
    prediction:
      originalCalculators.welfare?.welfare_eligibility === 'Eligible' &&
      originalCalculators.welfare?.calc_status !== 'client_reported'
        ? originalCalculators.welfare?.welfare_amount ?? null
        : null,
    calcStatus: currentCalculators.welfare?.calc_status,
  })

  // https://www.pivotaltracker.com/story/show/178521452
  useEffect(() => {
    let flags: Partial<IncludeFlags> = {}

    if (snap.delta === 0 && snap.prediction != null) {
      flags.include_snap = true
    }

    if (wic.value === wic.prediction && wic.prediction != null) {
      flags.include_wic = true
    }

    if (
      cashAssistance.value === cashAssistance.prediction &&
      cashAssistance.prediction != null
    ) {
      flags.include_welfare = true
    }

    if (Object.keys(flags).length > 0) {
      updateIncludeFlags(flags, FlagChangeSource.AUTOMATIC)
    }

    // todo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isEligibleForChildCare =
    currentCalculators.childcare?.childcare_eligibility === 'Eligible'

  const childCareDelta = isEligibleForChildCare
    ? currentCalculators.childcare?.childcare_savings
    : undefined

  const updateIncludeFlags = (
    flags: Partial<IncludeFlags>,
    flagChangeSource = FlagChangeSource.MANUAL,
  ) => {
    setFlagChangeSource(flagChangeSource)
    setIncludeFlags((prev) => ({ ...prev, ...flags }))
    setIsEnabled(true)
  }

  function postSurveyUrl(): URL {
    const urlMap: Record<Environment, string> = {
      DEVELOPMENT: `https://leapfund.app.law/leap-fund-calculatorstagingpost-output-survey?access_key=92ecMJzuhjYjdlfJY5avbdR1p`,
      STAGING: `https://leapfund.app.law/leap-fund-calculatorpost-output-surveyportastaging?access_key=dpD2M3IiC62qJN4AQyKOFKtmX`,
      PRODUCTION: `https://leapfund.app.law/leap-fund-calculatorpost-output-surveyporta?access_key=s1F0B52FmZUMGBmwc4Wu9Z8Rv`,
    }

    let urlString = urlMap[environment]
    let currentUrlSearchParams = new URL(window.location.href).searchParams

    const urlObj = new URL(urlString)
    urlObj.searchParams.set(
      `previous_session_id`,
      originalData.params.session_id,
    )
    urlObj.searchParams.set(`client_id`, originalData.params.client_id)
    urlObj.searchParams.set(`porta_user_id`, userAndOrgData.porta_user_id)
    urlObj.searchParams.set(
      `porta_organization_id`,
      userAndOrgData.porta_organization_id,
    )
    urlObj.searchParams.set(
      `porta_organization_name`,
      userAndOrgData.porta_organization_name,
    )
    let testCase = currentUrlSearchParams.get('test_case')
    if (testCase) {
      urlObj.searchParams.set(`test_case`, testCase)
    }
    return urlObj
  }

  const tooltips: Record<
    | 'ssdi'
    | 'ssiClient'
    | 'ssiSpouse'
    | 'ssiKids'
    | 'ssiOther'
    | 'unemployment',
    React.ReactNode[]
  > = {
    ssdi: [],
    ssiClient: [],
    ssiSpouse: [],
    ssiKids: [],
    ssiOther: [],
    unemployment: [],
  }

  const isIncomeChanged =
    currentData.incomes.main_income !== originalData.incomes.main_income

  // https://www.pivotaltracker.com/story/show/177386239
  if (isIncomeChanged && currentData.params.ssi_kids_amount > 0) {
    tooltips.ssiKids.push(
      `We don’t reflect changes in a guardian’s salary that may affect child’s SSI benefit amount`,
    )
  }

  // https://www.pivotaltracker.com/story/show/177386242
  if (isIncomeChanged && currentData.params.ssi_spouse_amount > 0) {
    tooltips.ssiSpouse.push(
      `We don’t reflect changes in salary that may affect spouse’s SSI benefit amount`,
    )
  }

  const isChangeInHouseHoldSize = !isEqual(
    currentData.params.persons,
    originalData.params.persons,
  )

  // https://www.pivotaltracker.com/story/show/177386245
  if (isChangeInHouseHoldSize && originalData.incomes.ssi_you_amount > 0) {
    tooltips.ssiClient.push(
      `A change in household size may impact your SSI amount`,
    )
  }

  // https://www.pivotaltracker.com/story/show/178059886
  if (isChangeInHouseHoldSize && originalData.incomes.ssi_kids_amount > 0) {
    tooltips.ssiKids.push(
      `A change in household size may impact your child’s SSI benefit amount`,
    )
  }

  const isIncomeOrHouseHoldSizeChanged =
    isIncomeChanged || isChangeInHouseHoldSize

  /**
   * generally tooltips should only be shown before income or house sizes change,
   * there are exceptions though (child care)
   */
  const isTooltips = !isIncomeOrHouseHoldSizeChanged

  const sgaAmount = currentSgaAmount(currentData.params.ssdi_blind)
  const isMonthlyIncomeGreaterThanSga =
    currentData.incomes.main_income > sgaAmount

  const isMonthlyIncomeLowerThanSga =
    currentData.incomes.main_income < sgaAmount

  // https://www.pivotaltracker.com/story/show/177481076
  if (
    isIncomeChanged &&
    isMonthlyIncomeGreaterThanSga &&
    originalData.incomes.ssdi_amount > 0
  ) {
    tooltips.ssdi.push(
      <>
        Your income may be above the SGA for SSDI,{' '}
        <a
          href="/guidebook/SSDI%20Primer%20c1d257f95d434f52822542deb2be042b.html"
          target="_blank"
          rel="noreferrer"
        >
          read more here
        </a>
      </>,
    )
  }

  // https://www.pivotaltracker.com/story/show/177747109
  if (
    isIncomeChanged &&
    isMonthlyIncomeLowerThanSga &&
    originalData.incomes.ssdi_amount > 0
  ) {
    tooltips.ssdi.push(
      `If you work more than one job, your SSDI amount could look different`,
    )
  }

  // https://www.pivotaltracker.com/story/show/177386259
  if (
    isIncomeChanged &&
    isMonthlyIncomeGreaterThanSga &&
    originalData.incomes.ssi_you_amount > 0 &&
    originalData.incomes.ssdi_amount > 0
  ) {
    tooltips.ssiClient.push(`A change in SSDI may impact your SSI amount`)
  }

  if (isIncomeChanged && originalData.incomes.unemployment_amount > 0) {
    tooltips.unemployment.push(
      <>
        A change in income may impact Unemployment Insurance eligibility. Find
        state-by-state resources at{' '}
        <a
          href="https://www.careeronestop.org/LocalHelp/UnemploymentBenefits/Find-Unemployment-Benefits.aspx"
          target="_blank"
          rel="noreferrer"
        >
          Unemployment Benefits Finder
        </a>{' '}
        to see how changes in income or hours can be affected.
      </>,
    )
  }

  if (
    isIncomeChanged &&
    originalData.incomes.unemployment_amount === 0 &&
    currentData.incomes.main_income === 0
  ) {
    tooltips.unemployment.push(
      <>
        You may be eligible for Unemployment Insurance. Review state-by-state
        eligibility requirements at{' '}
        <a
          href="https://www.careeronestop.org/LocalHelp/UnemploymentBenefits/Find-Unemployment-Benefits.aspx"
          target="_blank"
          rel="noreferrer"
        >
          Unemployment Benefits Finder
        </a>
        .
      </>,
    )
  }

  // https://www.pivotaltracker.com/story/show/177386264
  if (
    isIncomeChanged &&
    originalData.incomes.ssi_you_amount > 0 &&
    originalData.incomes.unemployment_amount > 0
  ) {
    tooltips.ssiClient.push(
      `A change in unemployment income may impact your SSI amount`,
    )
  }

  // https://www.pivotaltracker.com/story/show/178059467
  if (isIncomeChanged && originalData.incomes.ssi_other_amount > 0) {
    tooltips.ssiOther.push(
      `We don’t reflect changes in salary that may affect the other person in your household’s SSI benefit amount`,
    )
  }

  // https://www.pivotaltracker.com/story/show/177746465
  // https://www.pivotaltracker.com/story/show/177746736
  // https://www.pivotaltracker.com/story/show/177746837
  if (
    originalData.incomes.ssi_you_amount !== currentData.incomes.ssi_you_amount
  ) {
    tooltips.ssiClient.push(
      `Changes in SSI will not take effect immediately, but should take effect 2 months after a change in income`,
      <a
        href="/guidebook/SSI%20SSDI%20Income%20Exclusions%20a7f22dc3881d4ff386cb45e5a3379bef.html"
        target="_blank"
        rel="noreferrer"
      >
        There may be other types of income that impact your SSI amount
      </a>,
      <>
        To learn more about SSI eligibility, look at{' '}
        <a
          href="https://ssabest.benefits.gov/"
          target="_blank"
          rel="noreferrer"
        >
          BEST
        </a>
      </>,
    )
  }

  let ssiNodes: React.ReactNode[] = []
  if (
    originalData.incomes.ssi_you_amount != null &&
    originalData.incomes.ssi_you_amount > 0
  ) {
    ssiNodes.push(
      <IncomeLineItem
        label="Client SSI"
        amount={currentData.incomes.ssi_you_amount}
        baseAmount={originalData.incomes.ssi_you_amount}
        notice={tooltipNotice(tooltips.ssiClient)}
        event="Results Page - Tooltip: Client SSI"
      />,
    )
  }

  if (originalData.incomes.ssi_spouse_amount) {
    ssiNodes.push(
      <IncomeLineItem
        label="Spouse SSI"
        amount={currentData.incomes.ssi_spouse_amount}
        baseAmount={originalData.incomes.ssi_spouse_amount}
        notice={tooltipNotice(tooltips.ssiSpouse)}
        event="Results Page - Tooltip: Spouse SSI"
      />,
    )
  }

  if (originalData.incomes.ssi_kids_amount) {
    ssiNodes.push(
      <IncomeLineItem
        label="Kids SSI"
        amount={currentData.incomes.ssi_kids_amount}
        baseAmount={originalData.incomes.ssi_kids_amount}
        notice={tooltipNotice(tooltips.ssiKids)}
        event="Results Page - Tooltip: Kids SSI"
      />,
    )
  }

  if (originalData.incomes.ssi_other_amount) {
    ssiNodes.push(
      <IncomeLineItem
        label="Other SSI"
        amount={currentData.incomes.ssi_other_amount}
        baseAmount={originalData.incomes.ssi_other_amount}
        notice={tooltipNotice(tooltips.ssiOther)}
        event="Results Page - Tooltip: Other SSI"
      />,
    )
  }

  const incomePeriod = incomePeriodMap({
    incomeTimePeriod: originalData.params.income_time_period,
    initialIncome: originalData.params.income,
  })

  const [cashAssistanceNoticeIcon, cashAssistanceNoticeBody] = (():
    | [string, JSX.Element]
    | [undefined, undefined] => {
    /** benefit kitchen bug results in this field missing */
    const isCalcStatusFieldMissing = cashAssistance.calcStatus == null

    // https://linear.app/leapfund/issue/LF-110/zip-codes-that-were-not-predicting-cash-assistance-and-wic#comment-fc96f3f6
    // https://gist.github.com/schpet/b21aa9364b86d3bbc57b93fb8b87e267
    if (
      !isCalcStatusFieldMissing &&
      cashAssistance.calcStatus !== 'estimated'
    ) {
      return [
        exclamationIcon,
        <>
          We’re not currently predicting benefit eligibility for Cash Assitance
          in your locality
        </>,
      ]
    }

    if (!isTooltips || cashAssistance.delta == null) {
      return [undefined, undefined]
    }

    if (
      cashAssistance.delta === 0 &&
      flagChangeSource === FlagChangeSource.AUTOMATIC
    ) {
      return [
        exclamationIconGreen,
        <>
          Your self-reported amount matches our predicted amount. We've
          auto-checked the "Include" box so that we can update this prediction
          as you change income scenarios below.
        </>,
      ]
    }

    if (cashAssistance.delta < 0) {
      return [
        exclamationIcon,
        <>
          Your self-reported benefit amount is{' '}
          <span className={deltaClass(-1)}>
            {formatCurrency(Math.abs(cashAssistance.delta))}
          </span>{' '}
          lower than our prediction. Your actual benefit results may be affected
          by other factors or benefits.
        </>,
      ]
    }

    if (cashAssistance.delta > 0) {
      return [
        exclamationIcon,
        <>
          Your self-reported benefit amount is{' '}
          <span className={deltaClass(1)}>
            {formatCurrency(Math.abs(cashAssistance.delta))}
          </span>{' '}
          higher than our prediction. Your actual benefit results may be
          affected by other factors or benefits. If you would like to see our
          predictions instead of your self reported amount, check the include
          box.
        </>,
      ]
    }
    return [undefined, undefined]
  })()

  const [wicNoticeIcon, wicNotice] = (():
    | [string, JSX.Element]
    | [undefined, undefined] => {
    // avoid disabling wic checkbox (apr 19)
    // https://linear.app/leapfund/issue/LF-110/zip-codes-that-were-not-predicting-cash-assistance-and-wic#comment-2ab0b335
    //
    // https://linear.app/leapfund/issue/LF-110/zip-codes-that-were-not-predicting-cash-assistance-and-wic#comment-fc96f3f6
    // https://gist.github.com/schpet/b21aa9364b86d3bbc57b93fb8b87e267
    // if (wic.calcStatus !== 'estimated') {
    //   return [
    //     exclamationIcon,
    //     <>
    //       We’re not currently predicting benefit eligibility for WIC in your
    //       locality
    //     </>,
    //   ]
    // }

    if (!isTooltips) {
      return [undefined, undefined]
    }

    let notice =
      wic.delta != null ? (
        wic.delta === 0 && flagChangeSource === FlagChangeSource.AUTOMATIC ? (
          <>
            Your self-reported amount matches our predicted amount. We've
            auto-checked the "Include" box so that we can update this prediction
            as you change income scenarios below.
          </>
        ) : wic.delta < 0 ? (
          <>
            <p>
              Your self-reported benefit amount is{' '}
              <span className={deltaClass(-1)}>
                {formatCurrency(Math.abs(wic.delta))}
              </span>{' '}
              lower than our prediction. Your actual benefit results may be
              affected by other factors or benefits.{' '}
            </p>
          </>
        ) : wic.delta > 0 ? (
          <>
            Your self-reported benefit amount is{' '}
            <span className={deltaClass(1)}>{formatCurrency(wic.delta)}</span>{' '}
            higher than our prediction. Your actual benefit results may be
            affected by other factors or benefits. If you would like to see our
            predictions instead of your self reported amount, check the include
            box.
          </>
        ) : undefined
      ) : undefined

    let icon =
      flagChangeSource === FlagChangeSource.AUTOMATIC && wic.delta === 0
        ? exclamationIconGreen
        : undefined

    return [icon, notice]
  })()

  return (
    <div className="content">
      <div>
        {state.duplicateSession ? (
          <div className="alert alert-warning">
            <strong>
              Alert! Reusing this link in a client session? Start a fresh
              session by clicking <a href={state.newSessionUrl}>here</a>.
            </strong>{' '}
            We collect data for your organization through user sessions, and
            continuing will potentially overwrite previous work.
          </div>
        ) : null}

        <div className="output-area">
          <div className="output-area-body">
            <div className="output-area__client-id">
              Client ID: {originalData.params.client_id}
            </div>
            <div className="financial-information-container">
              <div className="financial-information">
                <div className="net-income">
                  <h4>Estimated Monthly Net Income</h4>
                  <div className={`net-income__total`}>
                    <span>
                      {query.isFetching ? (
                        <div className="spinner-border text-dark" role="status">
                          {/* <span className="sr-only">Loading...</span> */}
                        </div>
                      ) : (
                        formatCurrency(currentData[source].net_income)
                      )}
                    </span>
                  </div>
                </div>

                <div className="income-sources">
                  <h4>Income</h4>
                  <div className="income-include-flags__header">Include?</div>
                  <div className="income-source__line-items">
                    <div>
                      <IncomeLineItem
                        label="Main Income"
                        amount={currentData.incomes.main_income}
                        baseAmount={originalData.incomes.main_income}
                      />
                      <IncomeLineItem
                        label="Other Income"
                        amount={currentData.incomes.other_income}
                        baseAmount={originalData.incomes.other_income}
                      />

                      {ssiNodes.length > 0 ? (
                        <>
                          <div className="financial-information__line-item__label">
                            SSI
                          </div>
                          <ul>
                            {ssiNodes.map((ssiNode, i) => (
                              <li key={i}>{ssiNode}</li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <IncomeLineItem label="SSI" amount={0} baseAmount={0} />
                      )}
                      <IncomeLineItem
                        label="SSDI"
                        amount={currentData.incomes.ssdi_amount}
                        baseAmount={originalData.incomes.ssdi_amount}
                        notice={tooltipNotice(tooltips.ssdi)}
                        event="Results Page - Tooltip: SSDI"
                      />
                      <IncomeLineItem
                        label="Unemployment"
                        amount={currentData.incomes.unemployment_amount}
                        baseAmount={originalData.incomes.unemployment_amount}
                        notice={tooltipNotice(tooltips.unemployment)}
                        event="Results Page - Tooltip: Client UI"
                      />
                      <IncomeLineItem
                        label="Work Study"
                        amount={currentData.incomes.work_study_amount}
                        baseAmount={originalData.incomes.work_study_amount}
                      />
                    </div>
                    <div>
                      <IncomeLineItem
                        label="Child Support"
                        amount={currentData.incomes.child_support_income}
                        baseAmount={originalData.incomes.child_support_income}
                      />

                      <IncludeFlagCheckbox
                        id="include_welfare"
                        checked={includeFlags.include_welfare}
                        labelText="Include Cash Assistance"
                        onToggle={(checked) => {
                          if (checked) {
                            ahoy.track(
                              'Results Page – Include: Cash Assistance',
                              { language: 'JavaScript' },
                            )
                          } else {
                            ahoy.track(
                              'Results Page – Exclude: Cash Assistance',
                              { language: 'JavaScript' },
                            )
                          }
                          updateIncludeFlags({ include_welfare: checked })
                        }}
                        disabled={cashAssistance.calcStatus !== 'estimated'}
                      />

                      <IncomeLineItem
                        label="Cash Assistance"
                        amount={cashAssistance.value}
                        baseAmount={cashAssistance.originalValue}
                        noticeIcon={cashAssistanceNoticeIcon}
                        notice={cashAssistanceNoticeBody}
                        event="Results Page - Tooltip: Cash Assistance"
                      />

                      <IncludeFlagCheckbox
                        id="include_snap"
                        checked={includeFlags.include_snap}
                        onToggle={(checked) => {
                          if (checked) {
                            ahoy.track('Results Page – Include: SNAP', {
                              language: 'JavaScript',
                            })
                          } else {
                            ahoy.track('Results Page – Exclude: SNAP', {
                              language: 'JavaScript',
                            })
                          }
                          updateIncludeFlags({ include_snap: checked })
                        }}
                        labelText="Include SNAP"
                      />

                      <IncomeLineItem
                        label="SNAP"
                        amount={currentData[source].foodstamp_amount}
                        baseAmount={originalData.calculated.foodstamp_amount}
                        noticeIcon={
                          flagChangeSource === FlagChangeSource.AUTOMATIC &&
                          isTooltips &&
                          snap.delta != null &&
                          snap.delta === 0
                            ? exclamationIconGreen
                            : undefined
                        }
                        event="Results Page - Tooltip: SNAP"
                        notice={
                          isTooltips &&
                          snap.delta != null &&
                          (snap.delta === 0 &&
                          flagChangeSource === FlagChangeSource.AUTOMATIC ? (
                            <>
                              Your self-reported amount matches our predicted
                              amount. We've auto-checked the "Include" box so
                              that we can update this prediction as you change
                              income scenarios below
                            </>
                          ) : snap.delta < 0 ? (
                            <>
                              Your self-reported benefit amount is{' '}
                              <span className={deltaClass(-1)}>
                                {formatCurrency(Math.abs(snap.delta))}
                              </span>{' '}
                              lower than our prediction. Your actual benefit
                              results may be affected by other factors or
                              benefits.
                            </>
                          ) : snap.delta > 0 ? (
                            <>
                              Your self-reported benefit amount is{' '}
                              <span className={deltaClass(1)}>
                                {formatCurrency(Math.abs(snap.delta))}
                              </span>{' '}
                              higher than our prediction. Your actual benefit
                              results may be affected by other factors or
                              benefits. If you would like to see our predictions
                              instead of your self reported amount, check the
                              include box.
                            </>
                          ) : undefined)
                        }
                      />

                      <IncludeFlagCheckbox
                        id="include_wic"
                        checked={includeFlags.include_wic}
                        onToggle={(checked) => {
                          if (checked) {
                            ahoy.track('Results Page – Include: WIC', {
                              language: 'JavaScript',
                            })
                          } else {
                            ahoy.track('Results Page – Exclude: WIC', {
                              language: 'JavaScript',
                            })
                          }
                          updateIncludeFlags({ include_wic: checked })
                        }}
                        labelText="Include Wic"
                        // https://linear.app/leapfund/issue/LF-110/zip-codes-that-were-not-predicting-cash-assistance-and-wic#comment-2ab0b335
                        // disabled={wic.calcStatus !== 'estimated' || wic.delta == null}
                      />

                      <IncomeLineItem
                        label="WIC"
                        amount={wic.value}
                        baseAmount={wic.originalValue}
                        noticeIcon={wicNoticeIcon}
                        event="Results Page - Tooltip: WIC"
                        notice={wicNotice}
                      />
                    </div>
                  </div>
                </div>

                <div className="expenses">
                  <h4>Expenses</h4>
                  <div className="expenses-include-flags__header">Include?</div>
                  <div className="expenses__line-items">
                    <div>
                      <ExpenseLineItem
                        label="Taxes*"
                        amount={currentData.expenses.taxes}
                        baseAmount={originalData.expenses.taxes}
                      />
                      <ExpenseLineItem
                        label="Food"
                        amount={currentData.expenses.feed_family_cost}
                        baseAmount={originalData.expenses.feed_family_cost}
                      />
                      <ExpenseLineItem
                        label="Housing**"
                        amount={currentData.expenses.housing_costs}
                        baseAmount={originalData.expenses.housing_costs}
                      />
                      {marketRateRentDelta !== undefined && (
                        <div className="color-muted small pr-4">
                          {marketRateRentDelta === 0 ? (
                            <>
                              You're paying the market rate for housing based on
                              your zip code.
                            </>
                          ) : (
                            <>
                              You're paying{' '}
                              <span
                                className={deltaClass(marketRateRentDelta * -1)}
                              >
                                {formatCurrency(Math.abs(marketRateRentDelta))}
                              </span>{' '}
                              {marketRateRentDelta < 0 ? (
                                <>
                                  less than the market rate for housing based on
                                  your zip code.
                                </>
                              ) : (
                                <>
                                  more than the market rate for housing based on
                                  your zip code.
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <ExpenseLineItem
                        label="Health Care"
                        amount={currentData.expenses.healthcare}
                        baseAmount={originalData.expenses.healthcare}
                      />

                      <IncludeFlagCheckbox
                        id="include_child_care_voucher"
                        checked={includeFlags.include_child_care_voucher}
                        onToggle={(checked) => {
                          if (checked) {
                            ahoy.track('Results Page – Include: Childcare', {
                              language: 'JavaScript',
                            })
                          } else {
                            ahoy.track('Results Page – Exclude: Childcare', {
                              language: 'JavaScript',
                            })
                          }
                          updateIncludeFlags({
                            include_child_care_voucher: checked,
                          })
                        }}
                        labelText="Include Child Care Voucher"
                      />
                      <ExpenseLineItem
                        label="Child Care"
                        amount={currentData.expenses.childcare}
                        baseAmount={originalData.expenses.childcare}
                        event="Results Page - Tooltip: Chidcare"
                        notice={((): ReactElement | undefined => {
                          if (includeFlags.include_child_care_voucher) {
                            if (isEligibleForChildCare) {
                              return
                            } else {
                              return (
                                <>
                                  We predict that you are{' '}
                                  <strong>not eligible</strong> for a childcare
                                  subsidy or voucher. Because we don’t know the
                                  actual cost of childcare in your locality,
                                  we're showing the amount you had initially
                                  reported paying for childcare.
                                </>
                              )
                            }
                          }

                          if (!isTooltips) {
                            return
                          }

                          if (childCareDelta == null) {
                            return
                          }

                          if (childCareDelta === 0) {
                            return (
                              <>
                                There are no additional childcare savings that
                                we have found. You can check the "Include" box
                                to update this prediction as you change income
                                scenarios below.
                              </>
                            )
                          }

                          if (childCareDelta > 0) {
                            return (
                              <>
                                We predict that you could be receiving an
                                additional{' '}
                                <span className={deltaClass(1)}>
                                  {formatCurrency(Math.abs(childCareDelta))}
                                </span>{' '}
                                in assistance towards your child care costs.
                                Your actual benefit results may be affected by
                                other factors or benefits.
                              </>
                            )
                          }

                          return
                        })()}
                      />

                      <ExpenseLineItem
                        label="Child Support"
                        amount={currentData.expenses.child_support_expense}
                        baseAmount={originalData.expenses.child_support_expense}
                      />
                    </div>
                  </div>
                </div>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {<Benefits data={currentData} />}
                </ErrorBoundary>
              </div>

              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Disclaimer links={originalData.resources.links} />
              </ErrorBoundary>

              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {isDebug && <Details data={currentData} />}
              </ErrorBoundary>
            </div>
            <div className="text-center">
              <a
                href={postSurveyUrl().toString()}
                className="post-survey-link"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  ahoy.track('Results Page - Complete Session Button', {
                    language: 'JavaScript',
                  })
                }
              >
                Complete Client Session
              </a>
            </div>
          </div>

          <div className="new-inputs">
            <h2>What happens if I change…</h2>
            <>
              <div className="subheader">{incomePeriod.label}</div>
              <div className="slidecontainer">
                <input
                  type="range"
                  value={incomeInput}
                  onChange={(e) => {
                    const capitalizedPeriod =
                      originalData.params.income_time_period[0].toUpperCase() +
                      originalData.params.income_time_period.slice(1)
                    ahoy.track(`Results Page - ${capitalizedPeriod}ly Income`, {
                      language: 'JavaScript',
                    })
                    setIncomeInput(e.currentTarget.valueAsNumber)
                    setIsEnabled(true)
                  }}
                  min="0"
                  max={incomePeriod.max}
                  step={incomePeriod.step}
                  className="slider position-relative"
                  id="reactIncomeSlider"
                  data-format="$0,0"
                  style={{ zIndex: 1 }}
                />
                <IncomeVisualization
                  min={0}
                  max={incomePeriod.max}
                  step={incomePeriod.step}
                  currentData={currentData}
                  originalData={originalData}
                  includeFlags={includeFlags}
                  onClickIncome={(amount) => setIncomeInput(amount)}
                />
                <div className="slider__output__container">
                  <output htmlFor="reactIncomeSlider">
                    {formatCurrencyPrecise(incomeInput)}
                  </output>
                </div>
              </div>
              {incomePeriod.isHoursPerWeek && (
                <div className="slidecontainer">
                  <div className="subheader">
                    The number of hours I work per week?
                  </div>
                  <input
                    type="range"
                    value={incomeHoursInput}
                    onChange={(e) => {
                      ahoy.track('Results Page - Hours per week', {
                        language: 'JavaScript',
                      })
                      setIncomeHoursInput(e.currentTarget.valueAsNumber)
                      setIsEnabled(true)
                    }}
                    min="0"
                    max={60}
                    step={0.5}
                    className="slider"
                    id="reactIncomeHoursSlider"
                    data-format="$0,0"
                  />
                  <div className="slider__output__container">
                    <output htmlFor="reactIncomeSlider">
                      {incomeHoursInput.toFixed(2)}
                    </output>
                  </div>
                </div>
              )}
            </>
            <>
              <div className="subheader">My household size?</div>

              <div className="row form-row age-inputs">
                <div className="col-3 d-flex align-items-center">Adults</div>
                {adults.map((age, i) => (
                  <div className="col" key={i}>
                    <input
                      value={age}
                      className="form-control"
                      onChange={(e) => {
                        const { value } = e.currentTarget
                        if (value === '') {
                          ahoy.track('Results Page - Remove household', {
                            language: 'JavaScript',
                          })
                        } else {
                          ahoy.track('Results Page - Input household', {
                            language: 'JavaScript',
                          })
                        }

                        setAdults((prev) =>
                          prev.map((pAge, pI) => (pI === i ? value : pAge)),
                        )
                        setIsEnabled(true)
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="form-helper">Enter age for each adult.</div>

              <div className="row form-row age-inputs">
                <div className="col-3 d-flex align-items-center">
                  Children (Under 18)
                </div>
                {children.map((age, i) => (
                  <div className="col" key={i}>
                    <input
                      value={age}
                      className="form-control"
                      onChange={(e) => {
                        const { value } = e.currentTarget
                        if (value === '') {
                          ahoy.track('Results Page - Remove household', {
                            language: 'JavaScript',
                          })
                        } else {
                          ahoy.track('Results Page - Input household', {
                            language: 'JavaScript',
                          })
                        }
                        setChildren((prev) =>
                          prev.map((pAge, pI) => (pI === i ? value : pAge)),
                        )
                        setIsEnabled(true)
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="form-helper">Enter age for each child.</div>
            </>
          </div>
          <div className="buttons">
            <button
              type="button"
              className="btn btn-light border border-dark"
              onClick={() => {
                ahoy.track('Results Page - Recalculate Button', {
                  language: 'JavaScript',
                })
                query.refetch()
              }}
              disabled={query.isFetching}
            >
              {query.isFetching ? (
                <div style={{ width: 83 }}>
                  <Spinner />
                </div>
              ) : (
                <>Recalculate</>
              )}
            </button>
            {` `}
            <button
              type="button"
              className="btn btn-light border border-dark"
              onClick={() => {
                ahoy.track('Results Page - Reset Button', {
                  language: 'JavaScript',
                })
                const url = new URL(window.location.href)
                url.searchParams.set(`trigger`, `reset`)
                url.searchParams.set(`client_id`, originalData.params.client_id)
                window.location.href = url.toString()
              }}
            >
              Reset
            </button>
          </div>
          <footer>
            <span>
              Your privacy is our obsession. Your information is anonymous and
              is not transmitted to any benefits administration agency or
              financial institution.
            </span>
            <div className="mt-2">
              Thank you to Benefit Kitchen, whose benefits and cost datasets
              power our calculator.
            </div>
          </footer>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isDebug && <Details data={currentData} />}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

const IncludeFlagCheckbox = ({
  id,
  checked,
  onToggle,
  labelText,
  disabled,
}: {
  id: string
  onToggle: (val: boolean) => void
  labelText: string
  checked: boolean
  disabled?: undefined | boolean
}) => (
  <div className="financial-information__include-flag">
    <input
      type="checkbox"
      className=""
      id={id}
      name={id}
      onChange={(ev) => onToggle(ev.currentTarget.checked)}
      aria-label={labelText}
      checked={checked}
      disabled={disabled}
    />{' '}
  </div>
)

const deltaClass = (delta: number) =>
  delta === 0 ? '-same' : delta > 0 ? '-better' : '-worse'

type LineItemProps = {
  amount: number
  label: string
  delta?: number
  notice?: React.ReactNode
  noticeIcon?: string
  event?: string
}

const LineItem = ({
  amount,
  label,
  delta,
  notice,
  noticeIcon,
  event,
}: LineItemProps) => {
  return (
    <Collapsible
      onChange={() => {
        if (event) {
          ahoy.track(event, { language: 'JavaScript' })
        }
      }}
    >
      <div
        className={`financial-information__line-item ${
          delta != null ? deltaClass(delta) : ''
        }`}
      >
        <div className="financial-information__line-item__label">{label}</div>
        <div className="financial-information__line-item__value">
          {formatCurrency(amount)}
        </div>

        {notice ? (
          <CollapsibleTrigger className="financial-information__line-item__button">
            <img src={noticeIcon ?? exclamationIcon} alt="" />
          </CollapsibleTrigger>
        ) : null}

        {notice ? (
          <CollapsibleContent className="financial-information__line-item__info">
            {notice}
          </CollapsibleContent>
        ) : null}
      </div>
    </Collapsible>
  )
}

const IncomeLineItem = ({
  amount,
  baseAmount,
  ...rest
}: {
  amount: number
  baseAmount: number | null
} & Omit<LineItemProps, 'delta'>) => (
  <LineItem
    amount={amount}
    delta={baseAmount != null ? amount - baseAmount : undefined}
    {...rest}
  />
)

const ExpenseLineItem = ({
  amount,
  label,
  baseAmount,
  event,
  notice = undefined,
}: {
  amount: number
  label: string
  baseAmount: number
  event?: string
  notice?: React.ReactElement | undefined
}) => (
  <LineItem
    amount={amount}
    label={label}
    delta={(amount - baseAmount) * -1}
    notice={notice}
    event={event}
  />
)

function currentSgaAmount(isBlind: boolean): number {
  const amount = constants.SGA[isBlind ? 'blind' : 'notBlind']
  return currentAmount(amount)
}

const TooltipList = ({ tooltips }: { tooltips: React.ReactNode[] }) => {
  return (
    <ul style={{ paddingLeft: 18 }} className="mb-0 mt-1">
      {tooltips.map((text, i) => (
        <li key={`${i}: ${text}`}>{text}</li>
      ))}
    </ul>
  )
}

const tooltipNotice = (tooltips: React.ReactNode[]) => {
  if (tooltips.length === 0) return null
  return <TooltipList tooltips={tooltips} />
}

class PredictedField {
  public value: number
  public prediction: number | null
  public originalValue: number
  public calcStatus: BKApi.CalcStatus | undefined

  constructor(values: {
    value: number
    prediction: number | null
    originalValue: number
    calcStatus?: BKApi.CalcStatus
  }) {
    this.value = values.value
    this.prediction = values.prediction
    this.originalValue = values.originalValue
    this.calcStatus = values.calcStatus
  }

  get delta() {
    if (this.prediction == null) return null
    return this.value - this.prediction
  }
}

type IncomePeriod = {
  max: number
  step: number
  label: string
  isHoursPerWeek: boolean
}

/**
 * max adjusts roughly $20/hr above whatever initial income is (https://www.pivotaltracker.com/story/show/180016403)
 */
const incomePeriodMap = ({
  incomeTimePeriod,
  initialIncome,
}: {
  incomeTimePeriod: IncomeTimePeriod
  /** e.g. 14 if $14/hour, or 1200 for $1200/mo */
  initialIncome: number
}): IncomePeriod => {
  const weeksInAMonth = 4.345
  const daysInAWeek = 5
  const hoursInADay = 8
  const weeksInAYear = 52

  /** we don't want the slider to exceed $30/hr unless they earn more than that */
  const hourlyCap = 30
  const capMap: Record<IncomeTimePeriod, number> = {
    hour: hourlyCap,
    week: hourlyCap * hoursInADay * daysInAWeek,
    month: hourlyCap * hoursInADay * daysInAWeek * weeksInAMonth,
    year: hourlyCap * hoursInADay * daysInAWeek * weeksInAYear,
  }

  const delta =
    Math.max(initialIncome, capMap[incomeTimePeriod]) - initialIncome

  const map: Record<IncomeTimePeriod, IncomePeriod> = {
    hour: {
      max: initialIncome + delta,
      step: 0.25,
      label: 'My hourly rate?',
      isHoursPerWeek: true,
    },
    week: {
      max: initialIncome + delta,
      step: 10,
      label: 'My weekly income?',
      isHoursPerWeek: false,
    },
    month: {
      max: initialIncome + delta,
      step: 25,
      label: 'My monthly income?',
      isHoursPerWeek: false,
    },
    year: {
      max: initialIncome + delta,
      step: 500,
      label: 'My yearly income?',
      isHoursPerWeek: false,
    },
  }

  return map[incomeTimePeriod]
}
